<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
      <div class="iss-grid-wrap mt-16">
        <grid
          ref="gridRef"
          allow-selection
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 1640, y: gridHeight }"
        >
          <template #receiveConsult="{ text }">
            <a-tag v-if="text === true" color="success">接受</a-tag>
            <a-tag v-else-if="text === false" color="error">不接受</a-tag>
            <span v-else></span>
          </template>
          <template #operation="{ record }">
            <operation :options="options" :record="record" />
          </template>
        </grid>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
// Modal,
import { message, Tag } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
// import { downloadExcel } from '@/utils';
// import contactApi from '@/api/contact.js';
// import dictionaryApi from '@/api/dictionary.js';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ATag: Tag,
  },
  setup() {
    const gridRef = ref();
    const state = reactive({
      search: {},
      TagList: [],
    });
    const router = useRouter();
    // contactApi.getTag('', {}).then(list => {
    //   list?.forEach(item => {
    //     state.TagList.push({ value: item.tagCode, label: item.tagName });
    //   });
    // });
    // const getUploadOption = url => ({
    //   name: 'multipartfile',
    //   accept: '.xlc,.xlm,.xls,.xlsx,.xlt,.xlw',
    //   action:
    //       process.env.VUE_APP_API_SERVER + process.env.VUE_APP_API_BASE_URL + url,
    //   onChange: ({ file }) => {
    //     if (file.status === 'done') {
    //       const { count, wrongSize, error, result } = file.response.data;
    //       if (result) {
    //         Modal.info({
    //           title: `共导入 ${count} 条数据，其中：`,
    //           content: h('div', {}, [
    //             h(
    //                 'p',
    //                 { style: 'color: green' },
    //                 `成功导入 ${count - wrongSize} 条数据！`
    //             ),
    //             wrongSize
    //                 ? h(
    //                     'p',
    //                     { style: 'color: red' },
    //                     `失败 ${wrongSize} 条数据：${error}`
    //                 )
    //                 : '',
    //           ]),
    //         });
    //         gridRef.value.refreshGrid();
    //       } else {
    //         message.error('导入失败！');
    //       }
    //     } else if (file.status === 'error') {
    //       // message.error(`${info.file.name} file upload failed.`);
    //     }
    //   },
    // });
    return {
      gridRef,
      ...toRefs(state),
      items: [
        { key: 'fullName', label: '姓名' },
        { key: 'mobileNum', label: '手机' },
        { key: 'email', label: '邮箱' },
        { key: 'company', label: '公司' },
        { key: 'position', label: '职位' },
        { key: 'industryLv1Code', label: '行业' },
        // {
        //   key: 'province',
        //   label: '省份',
        //   type: 'select',
        //   valueKey: 'itemNameCn',
        //   labelKey: 'itemNameCn',
        //   url: `${dictionaryApi.provinceUrl}?code=CHINA_AREA`,
        //   linkedKey: 'city',
        // },
        {
          key: 'city',
          label: '城市',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
        },
        {
          key: 'tag',
          label: '标签',
          mode: 'multiple',
          type: 'select',
          dataset: state.TagList,
          separators: [','],
        },
        {
          key: 'gender',
          label: '性别',
          type: 'select',
          dataset: [
            { label: '男', value: '男' },
            { label: '女', value: '女' },
          ],
        },
        {
          key: 'receiveConsult',
          label: '营销资讯',
          type: 'select',
          dataset: [
            { value: '0', label: '不接受' },
            { value: '1', label: '接受' },
          ],
        },
      ],
      columns: [
        { dataIndex: 'fullName', title: '姓名', width: 120, ellipsis: true },
        { dataIndex: 'mobileNum', title: '手机', width: 120, ellipsis: true },
        { dataIndex: 'email', title: '邮箱', width: 180, ellipsis: true },
        { dataIndex: 'company', title: '公司', width: 180, ellipsis: true },
        { dataIndex: 'position', title: '职位', width: 120, ellipsis: true },
        {
          dataIndex: 'industryLv1Code',
          title: '行业',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'department', title: '部门', width: 120, ellipsis: true },
        { dataIndex: 'gender', title: '性别', width: 80, ellipsis: true },
        { dataIndex: 'province', title: '省份', width: 120, ellipsis: true },
        { dataIndex: 'city', title: '城市', width: 120, ellipsis: true },
        { dataIndex: 'unionId', title: '微信ID', width: 120, ellipsis: true },
        {
          dataIndex: 'receiveConsult',
          title: '营销资讯',
          slots: { customRender: 'receiveConsult' },
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'download',
          label: '联系人模版下载',
          icon: 'DownloadOutlined',
          permission: 'contact:download',
          // fnClick: () =>
          //     contactApi
          //         .downloadContactTemplate('contact:download')
          //         .then(({ name, data }) => downloadExcel(name, data)),
        },
        {
          type: 'import',
          label: '联系人导入',
          icon: 'ImportOutlined',
          permission: 'contact:import',
          // upload: getUploadOption(contactApi.importContactUrl),
        },
        // {
        //   type: 'download1',
        //   label: '行为模版下载',
        //   icon: 'DownloadOutlined',
        //   permission: 'contact:download1',
        //   fnClick: () => {
        //     contactApi
        //       .downloadActionTemplate('contact:download1')
        //       .then(({ name, data }) => downloadExcel(name, data));
        //   },
        // },
        // {
        //   type: 'import1',
        //   label: '行为导入',
        //   icon: 'ImportOutlined',
        //   permission: 'contact:import1',
        //   upload: getUploadOption(contactApi.importActionUrl),
        // },
        {
          type: 'export',
          label: '联系人导出',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: ids => {
            if (ids.length) {
              // contactApi
              //     .export('contact:export', [...ids])
              //     .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }
          },
        },
      ],
      options: [
        {
          type: 'view',
          label: '查看',
          icon: 'EyeTwoTone',
          permission: 'contact:view',
          fnClick: ({ id }) => router.push(`/datacenter/contact/${id}`),
        },
      ],
      url: '',
      gridHeight: document.body.clientHeight - 386,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
